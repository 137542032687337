import { Environment } from '@neuralegion/api';

export const environment: Omit<Environment, 'production'> = {
  version: 'v4.91.0-next.10814416793',
  commit: '2fa909',

  reCaptchaSiteKey: '6LdnAakUAAAAACiqoGaDrqZXEYbcTCOPtc37ABJp',
  segmentWriteKey: '',
  sentryDsn: 'https://303c0a925ad74928b19e0832384bcbab@sentry.io/1809867'
};
